.Login {
  text-align: center;

  display: flex;
  justify-content: center;

  padding-top: 10vh;

  width: 100%;
  /*height: 100%;*/

  background-color: #ddd;
}

.Form {
  width: 30vw;
  height: 35vh;

  min-width: 160px;
  max-width: 330px;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;

  gap: 50px;

  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 3em;
}

.OnAPI {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.OnAPI > * {
  margin: 0px;
}

.OnAPI > img {
  width: 60px;
}